import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-269c4652"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_login_button = _resolveComponent("login-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (!_ctx.listView)
    ? (_openBlock(), _createBlock(_component_ion_card, {
        key: 0,
        class: "booking-card-list-view",
        onClick: _ctx.openBooking
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "auto" }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "booking-card-img",
                        alt: "Golf Course",
                        src: `${_ctx.publicPath}assets/photos/course-${_ctx.randomCourse}.jpg`
                      }, null, 8, _hoisted_1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { size: "8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_title, { class: "ion-text-capitalize overflow-ellipsis" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.booking.course), 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.showStatus)
                            ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                                key: 0,
                                class: "ion-text-capitalize"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Status: " + _toDisplayString(_ctx.booking.booking_status?.name), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_card_subtitle, { color: "dark" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.formatToUSD(_ctx.booking.price)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_card_subtitle, { class: "ion-text-capitalize" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.booking.start)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_card_subtitle, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.formatTime(_ctx.booking.start)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_card_subtitle, { class: "ion-text-capitalize" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.booking.holes) + " Holes", 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_card_content, { class: "ion-hide-sm-down" }, {
                        default: _withCtx(() => [
                          (_ctx.canBook && _ctx.isAuthenticated)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 0,
                                class: "book-btn",
                                "router-link": _ctx.bookingConfirm
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("BOOK IT!")
                                ]),
                                _: 1
                              }, 8, ["router-link"]))
                            : (_ctx.canBook)
                              ? (_openBlock(), _createBlock(_component_login_button, {
                                  key: 1,
                                  buttonType: "signup",
                                  buttonTarget: "/user/update",
                                  "button-content": "Sign Up To Book"
                                }))
                              : _createCommentVNode("", true),
                          (_ctx.editable)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 2,
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal(_ctx.booking)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("EDIT")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"]))
    : (_openBlock(), _createBlock(_component_ion_card, {
        key: 1,
        class: "booking-card-standard-view"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: "booking-card-img",
            alt: "Golf Course",
            src: `${_ctx.publicPath}assets/photos/course-${_ctx.randomCourse}.jpg`
          }, null, 8, _hoisted_2),
          _createVNode(_component_ion_card_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_title, { class: "ion-text-capitalize" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.booking.course), 1)
                ]),
                _: 1
              }),
              (_ctx.showStatus)
                ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                    key: 0,
                    class: "ion-text-capitalize"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Status: " + _toDisplayString(_ctx.booking.booking_status?.name), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_card_subtitle, { color: "dark" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatToUSD(_ctx.booking.price)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_subtitle, { class: "ion-text-capitalize" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.booking.start)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_subtitle, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatTime(_ctx.booking.start)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_subtitle, { class: "ion-text-capitalize" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.booking.holes) + " Holes", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_content, { class: "ion-hide-sm-down" }, {
            default: _withCtx(() => [
              (_ctx.canBook && _ctx.isAuthenticated)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    class: "book-btn",
                    "router-link": _ctx.bookingConfirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("BOOK IT!")
                    ]),
                    _: 1
                  }, 8, ["router-link"]))
                : (_ctx.canBook)
                  ? (_openBlock(), _createBlock(_component_login_button, {
                      key: 1,
                      buttonType: "signup",
                      buttonTarget: "/user/update",
                      "button-content": "Sign Up To Book"
                    }))
                  : _createCommentVNode("", true),
              (_ctx.editable)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 2,
                    class: "book-btn"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("EDIT")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}