import { format, parseISO } from 'date-fns'

export default {
    methods: {
        formatDate(dateString: string) {
            return format(parseISO(dateString), 'MMMM do, yyyy');
        },
        formatTime(dateString: string) {
            return format(parseISO(dateString), 'p');
        }
    }
}
