import { defineStore } from 'pinia';
import api from '@/http/api';
import publicApi from '@/http/api-public';
import { BookingInterface, BookingStatusInterface, UpdateBookingDto } from '@bookingdev/ts-interfaces'
import { useAuthUserStore } from "@/stores/authUser/store"
import { presentToast } from '@/libs/toast';

export type BookingState = {
  authUserBookings: BookingInterface[],
  publicBookings: BookingInterface[],
  currentBooking: UpdateBookingDto,
  statuses: BookingStatusInterface[],
  loading: boolean,
};

export const useBookingsStore = defineStore('bookings', {
  state: () => ({
    authUserBookings: [],
    publicBookings: [],
    statuses: [],
    currentBooking: {},
    loading: false,
  } as BookingState),
  getters: {
    newBookingStatuses(state): BookingStatusInterface[] {
      const newBookingStatuses = ['available', 'draft']
      return state.statuses.filter(status => newBookingStatuses.includes(status.name))
    },
    bookingStatusByName(state) {
      return (statusName: string) => state.statuses.find(status => status.name === statusName)
    }
  },
  actions: {
    async getAllBookings() {
      this.loading = true
      const authUserStore = useAuthUserStore()
      const { user } = authUserStore
      if (!user.id) {
        await authUserStore.getAuthUser()
      }
      try {
        const res = await api.get(`/bookings/all`)
        this.publicBookings = res.data
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('There was an error fetching the user bookings', err)
        presentToast('error', 'Failed to fetch bookings.')
      }
      this.loading = false
    },
    async getBookingStatuses() {
      this.loading = true
      try {
        if (!this.statuses.length) {
          const statusesRes = await publicApi.get('/booking-statuses')
          this.statuses = statusesRes.data || []
        }
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('There was an error fetching the user', err)
        presentToast('error', 'Failed to fetch booking statuses.')
      }
      this.loading = false
    },
  }
})
