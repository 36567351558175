import { toastController } from "@ionic/vue";
import { alertCircle, checkmarkCircle, informationCircle, warningOutline } from "ionicons/icons";

const icons = {
  success: checkmarkCircle,
  info: informationCircle,
  error: alertCircle,
  warning: warningOutline
}

const classes = {
  success: 'toast-success',
  info: 'toast-info',
  error: 'toast-error',
  warning: 'toast-warning'
}

export async function presentToast(
  level: 'success' | 'info' | 'error' | 'warning',
  message: string,
  position: 'top' | 'middle' | 'bottom' = 'top',
  duration = 3000,
) {
  const toast = await toastController.create({
    message,
    duration,
    position,
    icon: icons[level],
    cssClass: classes[level],
  });
  await toast.present();
}
