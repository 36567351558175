import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (!_ctx.secondary)
    ? (_openBlock(), _createBlock(_component_ion_button, {
        key: 0,
        onClick: _ctx.login
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ]),
        _: 1
      }, 8, ["onClick"]))
    : (_openBlock(), _createBlock(_component_ion_button, {
        key: 1,
        color: "medium",
        size: "small",
        onClick: _ctx.login
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ]),
        _: 1
      }, 8, ["onClick"]))
}