import { createAuth0 } from '@auth0/auth0-vue'
import type { Auth0Plugin } from '@auth0/auth0-vue'
import { domain as auth0Domain, clientId, callbackUri, audience } from "../auth.config";
import { useAuth0 } from "@auth0/auth0-vue";
import { Browser } from "@capacitor/browser";
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/vue';

const isNativeApp = Capacitor.getPlatform() !== 'web' && (isPlatform('ios') || isPlatform('android'));

const client: Auth0Plugin = createAuth0({
  domain: auth0Domain,
  clientId: clientId,
  useRefreshTokens: isNativeApp,
  useRefreshTokensFallback: !isNativeApp,
  authorizationParams: {
    redirect_uri: callbackUri,
    audience: audience,
  },
  cacheLocation: 'localstorage',
})

export default client

export const getAccessToken = async () => {
  const accessToken = await client.getAccessTokenSilently()
  if (!accessToken) {
    const { logout } = useAuth0();
    await logout({
      logoutParams: {
        returnTo: callbackUri,
      },
      openUrl: (url: string) =>
        Browser.open({
          url,
          windowName: "_self",
        }),
    });
  }
  return accessToken
}
