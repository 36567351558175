import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36bb9b62"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "public-profile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_user_profile = _resolveComponent("user-profile")!
  const _component_bookings_display = _resolveComponent("bookings-display")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, null, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ion_loading, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_user_profile, { user: _ctx.current }, null, 8, ["user"]),
            _createVNode(_component_bookings_display, {
              bookingsArray: _ctx.getAvailableBookings,
              canBook: true,
              displayType: "available"
            }, null, 8, ["bookingsArray"])
          ]))
    ]),
    _: 1
  }))
}