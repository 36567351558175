
import { defineComponent } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { callbackUri } from "../auth.config";
import { Browser } from "@capacitor/browser";
import { IonAvatar, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonButton, useIonRouter, menuController  } from "@ionic/vue";
import { ban, checkmark } from 'ionicons/icons';

export default defineComponent({
  name: 'UserProfile',
  props: {
    user: {
      type: Object,
      required: true
    },
    isAuthUser: {
      type: Boolean,
      default: false
    },
    openUserProfileModal: {
      type: Function,
    },
  },
  components: { IonAvatar, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonButton },
  setup() {
    const { logout } = useAuth0();
    const router = useIonRouter()
    const onLogout = async () => {
      await logout({
        logoutParams: {
          returnTo: callbackUri,
        },
        openUrl: (url: string) =>
          Browser.open({
            url,
            windowName: "_self",
          }),
      });
      menuController.close()
      router.push('/')
    };
    return {
      onLogout,
      ban,
      checkmark
    };
  },
  computed: {
    topContent() {
      const { state, handicap, price_range } = this.$props.user.profile
      return {
        location: state,
        handicap,
        price: price_range?.display
      }
    }
  },
  methods: {
    openEditModal() {
      if (this.$props.isAuthUser && this.$props.openUserProfileModal) {
        return this.$props.openUserProfileModal(true)
      }
    }
  }
});
