
import {
    IonButton,
    IonLoading
} from "@ionic/vue";
import { defineComponent } from "vue";
import BookingCard from '@/components/BookingCard.vue'
import { usePublicUsersStore } from "@/stores/publicUsers/store";
import { useAuthUserStore } from "@/stores/authUser/store";
import { storeToRefs } from "pinia";
import { useRoute } from 'vue-router';

export default defineComponent({
    name: "PaymentPage",
    components: {
        IonButton,
        IonLoading,
        BookingCard
    },
    setup() {
        const route = useRoute();
        const { bookingId } = route.params;
        const parsedId: number = parseInt((bookingId) as string)
        const publicUsersStore = usePublicUsersStore()
        const authUserStore = useAuthUserStore()
        const { bookingById } = publicUsersStore
        const { loading } = storeToRefs(publicUsersStore)
        const booking = bookingById(parsedId)
        return { loading, booking, authUserStore }
    },
    methods: {
        async claimBooking() {
            if (this.booking) {
                await this.authUserStore.claimBooking(this.booking)
            }
        } 
    }
});
