import axios from 'axios'
import { getAccessToken } from '../plugins/auth'

const config = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const api = axios.create(config)

api.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${await getAccessToken()}`
  return config
})

export default api
