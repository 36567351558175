import axios from 'axios'

const config = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const publicApi = axios.create(config)

publicApi.interceptors.request.use(async (config) => {
  return config
})

export default publicApi
