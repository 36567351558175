
import { defineComponent } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { Browser } from "@capacitor/browser";
import { IonButton } from "@ionic/vue";

export default defineComponent({
  name: 'login-button',
  components: {
    IonButton,
  },
  props: {
    secondary: {
      type: Boolean,
      default: false
    },
    buttonType: {
      type: String,
      default: 'login'
    },
    buttonTarget: {
      type: String,
      default: '/profile'
    },
    buttonContent: {
      type: String,
    }
  },
  data() {
    return {
      buttonText: this.buttonContent ? this.buttonContent : this.buttonType === 'signup' ? 'Create New Account' : 'Sign In',
    }
  },
  setup(props) {
    const { loginWithRedirect } = useAuth0();
    const login = async () => {
      await loginWithRedirect({
        appState: {
          target: props.buttonTarget,
        },
        authorizationParams: {
          screen_hint: props.buttonType,
        },
        openUrl: (url: string) =>
          Browser.open({
            url,
            windowName: "_self",
          }),
      });
    };
    return { login };
  },
});
