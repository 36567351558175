import { defineStore } from "pinia";
import { PublicUser } from '@bookingdev/ts-interfaces'
import publicApi from '@/http/api-public';
import { BookingInterface } from '@bookingdev/ts-interfaces'
import { stringToStartCase } from "@/libs/formatter";
import { presentToast } from "@/libs/toast";

export type PublicUserState = {
  users: PublicUser[],
  current: PublicUser | Partial<PublicUser>,
  currentFilters: any,
  loading: boolean,
};

export const usePublicUsersStore = defineStore('publicUsers', {
  state: () => ({
    users: [],
    current: {},
    currentFilters: {},
    loading: false
  } as PublicUserState),
  getters: {
    getAvailableBookings(): BookingInterface[] {
      return this.currentBookings.filter(booking => booking.booking_status?.name === 'available')
    },
    currentBookings(state): BookingInterface[] {
      return state.current.bookings || []
    },
    bookingById() {
      return (bookingId: number) => this.currentBookings.find(booking => booking.id === bookingId)
    },
    filtersToString(): string {
      const filters = Object.keys(this.currentFilters).map(key => stringToStartCase(key)).join(', ')
      return `Filters: ${filters}`
    }
  },
  actions: {
    async getUsers() {
      this.loading = true
      try {
        const usersRes = await publicApi.get('/user/all')
        this.users = usersRes.data || []
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('There was an error fetching the users', err)
        presentToast('error', 'Failed to fetch users.')
      }
      this.loading = false
    },
    async searchUsers(search: string) {
      this.loading = true
      this.setFilters({})
      try {
        if (search === '') {
          this.getUsers()
        } else {
          const words = search.split(' ');
          let url = `/user/search?`
          words.forEach((s, i) => {
            url = url += i === 0 ? `strings[]=${s}` : `&strings[]=${s}`
          })
          const usersRes = await publicApi.get(url)
          this.users = usersRes.data || []
        }
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('There was an error fetching the users', err)
        presentToast('error', 'Failed to search users. Please try again.')
      }
      this.loading = false
    },
    async searchUsersWithFilters(filters: any) {
      this.loading = true
      try {
        this.currentFilters = filters;
          const qs = Object.keys(filters).map(key => Array.isArray(filters[key])
            ? filters[key].map((v: any) => `${key}[]=${v}`).join('&')
            : `${key}=${filters[key]}`
          ).join('&');
          const usersRes = await publicApi.get('/user/filter?' + qs)
          this.users = usersRes.data || []
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('There was an error fetching the users', err)
        presentToast('error', 'Failed to search users. Please try again.')
      }
      this.loading = false
    },
    async setUser(user: PublicUser) {
      this.current = user
    },
    async setFilters(filters: any) {
      this.currentFilters = filters
    },
    async getUser(id: number) {
      this.loading = true
      try {
        const user = this.users.find(u => u.id === id)
        if (!user) {
          const userRes = await publicApi.get(`/user/${id}`)
          this.users.push(userRes.data)
          this.setUser(userRes.data)
        }
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('There was an error fetching the user', err)
        presentToast('error', 'Failed to fetch user. Please try again.')
      }
      this.loading = false
    },
  },
})
