import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72f46be9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bookings-container" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_booking_card = _resolveComponent("booking-card")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.displayTitle), 1)
              ]),
              (!_ctx.bookingsArray?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_ion_label, { color: "medium" }, {
                      default: _withCtx(() => [
                        _createTextVNode("No Active Bookings")
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (!_ctx.listView)
        ? (_openBlock(), _createBlock(_component_ion_row, {
            key: 0,
            class: "ion-justify-content-left"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingsArray, (booking, key, index) => {
                return (_openBlock(), _createBlock(_component_ion_col, {
                  key: index,
                  size: "12",
                  "size-md": "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_booking_card, {
                      booking: booking,
                      showStatus: _ctx.showStatus,
                      canBook: _ctx.canBook,
                      editable: _ctx.editable,
                      openModal: _ctx.openModal,
                      listView: _ctx.listView
                    }, null, 8, ["booking", "showStatus", "canBook", "editable", "openModal", "listView"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingsArray, (booking, key, index) => {
                return (_openBlock(), _createBlock(_component_ion_col, {
                  key: index,
                  size: "12",
                  "size-sm": "6",
                  "size-lg": "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_booking_card, {
                      booking: booking,
                      showStatus: _ctx.showStatus,
                      canBook: _ctx.canBook,
                      editable: _ctx.editable,
                      openModal: _ctx.openModal,
                      listView: _ctx.listView
                    }, null, 8, ["booking", "showStatus", "canBook", "editable", "openModal", "listView"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}