export function formatPhone(phone: string): string {
  if (!phone) {
    console.error("Phone number formatters requires value to format");
  }
  return phone.replace(/[()-]/g, "").replace(" ", "").trim();
}

export function formatCurrency(value: string): string {
  const stripped = stringCurrencyToNumber(value)
  if (stripped) {
    return formatToUSD(stripped)
  }
  return '$0'
}

export function formatToUSD(value: number): string{
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
}

export function stringCurrencyToNumber(value: string): number {
  return Number(value.replace(/[$,]/g, "").trim());
}

export function stringToStartCase(camelCase: string) {
  return camelCase
    .replace(/([A-Z])/g, function(match) {
      return " " + match;
    })
    .replace(/^./, function(match) {
      return match.toUpperCase();
    });
}
