import { User as Auth0User } from '@auth0/auth0-vue';
import { defineStore } from 'pinia';
import api from '@/http/api';
import { CreateUserDto, AuthUser, UpdateProfileDto, UpdateUserDto, CreateBookingDto, BookingInterface, ClaimBookingDto, UpdateBookingDto } from '@bookingdev/ts-interfaces'
import { useBookingsStore } from "@/stores/bookings/store"
import { presentToast } from '@/libs/toast';

interface profilePictureUpdate {
  base64Photo: string,
  webPath: string,
  blob: Blob,
  fileName: string
}
export type UserState = {
  user: AuthUser,
  loading: boolean,
};

export const useAuthUserStore = defineStore('authUser', {
  state: () => ({
    user: {},
    loading: false,
  } as UserState),
  getters: {
    getAvailableBookings(): BookingInterface[] {
      return this.authUserBookings.filter(booking => booking.booking_status?.name === 'available')
    },
    authUserBookings(state): BookingInterface[] {
      return state.user.bookings || []
    },
    claimedBookings(state): BookingInterface[] {
      return state.user.claimedBookings || []
    }
  },
  actions: {
    async createUser(user: CreateUserDto) {
      this.loading = true
      try {
        const res = await api.post('/user', user)
        this.user = res.data
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('There was an error creating the user', err)
      }
      this.loading = false
    },
    async getAuthUser(force = false) {
      this.loading = true
      try {
        if (!this.user.id || force ) {
          const userRes = await api.get('/user')
          this.user = userRes.data || {}
        }
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('There was an error fetching the user', err)
      }
      this.loading = false
    },
    async findOrCreate(user: Auth0User) {
      this.loading = true
      try {
        if (!this.user.id) {
          const userRes = await api.get('/user')
          this.user = userRes.data || {}
        }
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('Unable to fetch the user')
        const createUserDto: CreateUserDto = {
          first_name: user.given_name,
          last_name: user.family_name,
          email: user.email as string,
          email_verified: user.email_verified as boolean,
          phone: user.phone_number,
          picture_url: user.picture,
          is_active: true,
        }
        await this.createUser(createUserDto)
      }
      this.loading = false
    },
    async updateProfilePicture(pictureData: profilePictureUpdate) {
      this.loading = true
      const userSnapshot = this.user
      const { webPath, blob, fileName } = pictureData
      try {
        if (webPath) {
          this.user = {
            ...this.user,
            ...{ picture_url: webPath }
          }
        }
        const formData = new FormData();
        formData.append('photo', blob, fileName)
        formData.append('name', fileName)
        const updateRes = await api.patch(`/user/${this.user.id}/image`, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
        this.user = updateRes.data
        presentToast('success', 'Profile picture updated!')
      } catch (err) {
        // revert optimistic update
        this.user = userSnapshot
        // TO DO implement better error handling for store http requests
        console.error('There was an error updating the profile picture', err)
        presentToast('error', 'Failed to update profile picture. Please try again.')
      }
      this.loading = false
    },
    async updateUserProfile(user: UpdateUserDto, profile: UpdateProfileDto) {
      this.loading = true
      const userSnapshot = this.user
      const profileSnapshot = this.user.profile
      // User Update
      try {
        this.user = {
          ...this.user,
          ...user
        }
        const updateRes = await api.patch(`/user/${this.user.id}`, user)
        this.user = updateRes.data
      } catch (err) {
        // revert optimistic update
        this.user = userSnapshot
        // TO DO implement better error handling for store http requests
        console.error('There was an error creating the user', err)
        presentToast('error', 'Failed to update profile. Please try again.')
      }
      // Profile Update
      try {
        this.user.profile = {
          ...this.user.profile,
          ...profile
        }
        const profileRes = await api.patch(`/user/${this.user.id}/profile`, profile)
        this.user.profile = profileRes.data
        presentToast('success', 'Profile updated!')
      } catch (err) {
        // revert optimistic update
        this.user.profile = profileSnapshot
        // TO DO implement better error handling for store http requests
        console.error('There was an error creating the user', err)
        presentToast('error', 'Failed to update profile. Please try again.')
      }
      this.loading = false
    },
    async createBooking(booking: CreateBookingDto) {
      this.loading = true
      const bookingsSnapshot = this.user.bookings
      try {
        this.user.bookings = [
          ...this.user.bookings,
          booking,
        ]
        const bookingRes = await api.post(`/user/${this.user.id}/bookings`, booking)
        this.user.bookings = [
          ...bookingsSnapshot,
          bookingRes.data,
        ]
        presentToast('success', 'Booking created!')
      } catch (err) {
        // revert optimistic update
        this.user.bookings = bookingsSnapshot
        // TO DO implement better error handling for store http requests
        console.error('There was an error creating the booking', err)
        presentToast('error', 'Failed to create booking. Please try again.')
      }
      this.loading = false
    },
    async updateBooking(bookingId: number, updateValues: UpdateBookingDto) {
      this.loading = true
      try {
        const index = this.user.bookings.findIndex(booking => booking.id === bookingId)
        if (index === -1) {
          console.error('There was an error updating the booking: Local booking not found')
        } 
        const bookingRes = await api.patch(`/user/${this.user.id}/bookings/${bookingId}`, updateValues)
        this.user.bookings[index] = bookingRes.data
        presentToast('success', 'Booking updated!')
      } catch (err) {
        // TO DO implement better error handling for store http requests
        console.error('There was an error updating the booking', err)
        presentToast('error', 'Failed to update booking. Please try again.')
      }
      this.loading = false
    },
    async claimBooking(claimedBooking: BookingInterface) {
      this.loading = true
      const claimedBookingsSnapshot = this.user.claimedBookings
      try {
        const bookingsStore = useBookingsStore()
        if (!bookingsStore.statuses.length) {
          await bookingsStore.getBookingStatuses()
        }
        const bookedStatus = bookingsStore.bookingStatusByName('booked')
        if (bookedStatus) {
          claimedBooking.booking_status = { id: bookedStatus.id, name: bookedStatus.name }
          const claimObj: ClaimBookingDto = {
            consumer_id: this.user.id,
            status_id: bookedStatus.id
          }
          this.user.claimedBookings = [
            ...this.user.claimedBookings,
            { ...claimedBooking, ...claimObj}
          ]
          await api.patch(`/claim-booking/${claimedBooking.id}`, claimObj)
        }
        presentToast('success', 'Booking claimed!')
      } catch (error) {
        // revert optimistic update
        this.user.claimedBookings = claimedBookingsSnapshot
        // TO DO implement better error handling for store http requests
        console.error('There was an error creating the booking', error)
        presentToast('error', 'Failed to claim booking. Please try again.')
      }
    }
  },
})
