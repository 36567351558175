
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseLayout",
  props: {
    padding: {
      type: Boolean,
      default: true
    }
  },
  components: {
    IonContent,
    IonPage,
  },
});
