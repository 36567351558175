import { Capacitor } from "@capacitor/core";
import { isPlatform } from "@ionic/vue";
import config from "../capacitor.config";

export const domain = process.env.VUE_APP_AUTH0_DOMAIN;
export const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
export const audience = process.env.VUE_APP_AUTH0_AUDIENCE;
const appId = config.appId;

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
const auth0Domain = domain;
const iosOrAndroid = Capacitor.getPlatform() !== 'web' && (isPlatform('ios') || isPlatform('android'));

export const callbackUri = iosOrAndroid
    ? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
    : process.env.VUE_APP_APP_URL;
