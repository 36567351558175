
import { defineComponent } from "vue";
import { IonCol, IonGrid, IonRow, IonLabel } from "@ionic/vue";
import BookingCard from '@/components/BookingCard.vue'

export default defineComponent({
  name: 'BookingsDisplay',
  components: { BookingCard, IonCol, IonGrid, IonRow, IonLabel },
  props: {
    bookingsArray: {
      type: Array
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    canBook: {
      type: Boolean,
      default: false
    },
    displayType: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: false
    },
    openModal: {
      type: Function,
    },
    listView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayTitle() {
      if (this.$props.displayType === 'claimed') {
        return 'Claimed Bookings'
      }
      if (this.$props.displayType === 'available') {
        return 'Available Bookings'
      }
      return 'User Bookings'
    }
  }
});
