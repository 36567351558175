import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import AppTabs from '@/components/AppTabs.vue'
import PaymentPage from '@/views/PaymentPage.vue'
import PublicProfilePage from '@/views/PublicProfilePage.vue'
import { authGuard as auth0Guard } from "@auth0/auth0-vue";
import getAuthUser from './guards/getAuthUser';

const authBeforeEnter = [
  auth0Guard,
  getAuthUser,
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/',
    component: AppTabs,
    children: [
      {
        path: '',
        redirect: '/home',
      },
      {
        path: 'home',
        component: () => import('../views/HomePage.vue'),
        beforeEnter: getAuthUser
      },
      {
        path: 'explore',
        component: () => import('../views/ResultsPage.vue'),
        beforeEnter: getAuthUser
      },
      {
        path: 'profile',
        component: () => import('../views/MyProfilePage.vue'),
        beforeEnter: auth0Guard,
      },
      {
        path: 'bookings',
        component: () => import('../views/MyBookingsPage.vue'),
        beforeEnter: authBeforeEnter
      },
    ],
  },
  {
    path: '/profile/:id',
    name: 'Public Profile',
    component: PublicProfilePage,
  },
  {
    path: '/bookings/:bookingId/payment',
    name: 'Payment Page',
    component: PaymentPage,
    beforeEnter: authBeforeEnter
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app')?.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0,0);
    return { top: 0, behavior: "smooth" };
  }
})

export default router
