
import { usePublicUsersStore } from "@/stores/publicUsers/store";
import UserProfile from "@/components/UserProfile.vue";
import BookingsDisplay from "@/components/BookingsDisplay.vue"
import { IonLoading } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PublicProfilePage",
  components: {
    IonLoading,
    UserProfile,
    BookingsDisplay
  },
  setup() {
    const publicUsersStore = usePublicUsersStore()
    const { current, loading, getAvailableBookings } = storeToRefs(publicUsersStore)
    return { publicUsersStore, current, loading, getAvailableBookings }
  },
  async beforeCreate() {
    if (!this.current.id) {
      const id: number = parseInt((this.$route.params.id) as string)
      await this.publicUsersStore.getUser(id)
    }
  }
});
