
import { useAuth0 } from '@auth0/auth0-vue';
import { IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon } from '@ionic/vue';
import { searchOutline, calendarClearOutline, personOutline, homeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  components: { IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon },
  setup() {
    const { isAuthenticated } = useAuth0()
    return {
      calendarClearOutline,
      personOutline,
      searchOutline,
      homeOutline,
      isAuthenticated
    };
  },
});
