
import { defineComponent } from "vue";
import { useIonRouter, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, IonCardSubtitle, IonGrid, IonRow, IonCol } from "@ionic/vue";
import formatDateMixin from '../mixins/format-date'
import { formatToUSD } from "@/libs/formatter";
import LoginButton from '@/components/LoginButton.vue'
import { useAuth0 } from "@auth0/auth0-vue";

export default defineComponent({
  name: 'BookingCard',
  components: { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, LoginButton, IonCardSubtitle, IonGrid, IonRow, IonCol },
  props: [ 'booking', 'showStatus', 'canBook', 'editable', 'openModal', 'listView'],
  mixins: [formatDateMixin],
  setup(props) {
    const bookingConfirm = `/bookings/${props.booking.id}/payment`
    const router = useIonRouter()
    const { isAuthenticated } = useAuth0();
    const publicPath = process.env.BASE_URL
    return { formatToUSD, isAuthenticated, publicPath, router, bookingConfirm }
  },
  computed: {
    randomCourse() {
      return Math.floor(Math.random() * 31) + 1
    }
  },
  methods: {
    openBooking() {
      if (this.editable) {
        return this.$props.openModal(this.$props.booking)
      }
      this.router.push(this.bookingConfirm)
    }
  }
});
